import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  getPageGridModel,
  IPageWithGridModel,
  PageWithGridComponent
} from '../../../oex-ui-kit/base/page-with-grid.component';
import {IAdminNewsletter} from '../../services/api.types';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {APIService} from '../../services/api.service';
import {GridComponent, IGridColumnDef, IGridConfig, IRowActionEvent} from '../../../oex-ui-kit/components/grid/grid';
import {SearchComponent} from '../../../oex-ui-kit/components/search/search';
import {TooltipDirective} from '../../../oex-ui-kit/components/tooltip/tooltip.directive';
import {ModalService} from '../../services/modal.service';
import {NgSelectModule} from '@ng-select/ng-select';
import {DialogService} from '../../services/dialog.service';

export const resolveAdminNewsletter: ResolveFn<IPageWithGridModel<IAdminNewsletter>> = async (route: ActivatedRouteSnapshot) => {
  const api = inject(APIService);
  const model = getPageGridModel<IAdminNewsletter>(route);

  model.data = await api.getAdminNewsletter(model.search, model.page, model.sorting);

  /*model.data.items.forEach(c => {
  });*/

  return model;
}

@Component({
  selector: 'st-admin-newsletter',
  imports: [
    SearchComponent,
    GridComponent,
    NgSelectModule,
    TooltipDirective
  ],
  templateUrl: './admin-newsletter.component.html',
  styleUrl: './admin-newsletter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminNewsletterComponent extends PageWithGridComponent {
  private modal = inject(ModalService);
  private api = inject(APIService);
  private ds = inject(DialogService);

  override model!: IPageWithGridModel<IAdminNewsletter>;
  override config: IGridConfig = {
    sortingField: 'n',
    sortingOrder: 'asc',
    rowActions: [{id: 'delete', 'icon': '/assets/img/icon-delete.svg', tooltip: 'Delete newsletter'}]
  }
  columns: IGridColumnDef<IAdminNewsletter>[] = [
    {
      field: 'name',
      text: 'Name',
      template: 'nameColumn',
      initialSorting: 'asc',
      width: '25%',
      sorting: 'n'
    },
    {
      field: 'subject',
      text: 'Subject',
      initialSorting: 'asc',
      width: '25%',
      sorting: 's',
      template: 'textColumn'
    },
    {
      field: 'body',
      text: 'Body',
      initialSorting: 'asc',
      width: '35%',
      template: 'textColumn'
    },
    {
      field: 'base_template_name',
      text: 'Base',
      initialSorting: 'asc',
      width: '10%',
      sorting: 'bt'
    },
    {
      field: 'created',
      text: 'Created',
      width: '10%',
      sorting: 'c',
      template: 'date'
    },
    {
      field: 'updated',
      text: 'Updated',
      width: '10%',
      sorting: 'u',
      template: 'date'
    }
  ];

  protected override resolver = resolveAdminNewsletter;

  onAddClick() {
    this.showEditor();
  }

  onEditClick(newsletter: IAdminNewsletter) {
    this.showEditor(structuredClone(newsletter));
  }

  onGridAction(e: IRowActionEvent<IAdminNewsletter>) {
    switch (e.action.id) {
      case 'delete':
        this.askForDeletion(e.data);
        break;
    }
  }

  private showEditor(newsletter?: IAdminNewsletter) {
    const inputs = newsletter ? {model: newsletter} : undefined;
    void this.modal.show({
      component: import('./admin-newsletter-editor.component'),
      inputs: {
        ...inputs,
        onSaveSuccess: () => this.refreshData()
      },
      buttons: []
    });
  }

  private onSaveSuccess(n: IAdminNewsletter) {
    /*const idx = this.model.data.items.findIndex(letter => letter.id === n.id);
    if (idx === -1) {
      // New record
      this.model.data.items.splice(0, 0, n);
    } else {
      // Exist record
      this.model.data.items[idx] = n;
    }
    this.model.data.items = [...this.model.data.items];
    this.grid?.detectChanges();*/
  }

  private askForDeletion(newsletter: IAdminNewsletter) {
    void this.ds.showConfirmDialog(`Are you sure you want to delete "${newsletter.name}" newsletter?`, async () => {
      this.deleteNewsletter(newsletter);
    });
  }

  private async deleteNewsletter(newsletter: IAdminNewsletter) {
    if (!newsletter.id) {
      return;
    }
    this.ps.show();
    try {
      await this.api.deleteAdminNewsletter(newsletter.id);
      const idx = this.model.data.items.indexOf(newsletter);
      if (idx !== -1) {
        this.model.data.items.splice(idx, 1);
        this.grid()?.detectChanges();
      }
    } catch (e) {
      this.modal.showError(e);
    } finally {
      this.ps.hide();
    }
  }
}
